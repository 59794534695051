import CRUDModule from './CRUDModule';
import { couponsAPIsConstants, couponsActions } from '../enums/CouponsReportEnums';
import axios from 'axios';
import DownloadService from '@/core/services/DownloadService';

const couponsModule = {
  ...CRUDModule, 
  namespaced: true,
  state: {
    ...CRUDModule.state,
    sum: null, 
    apiPath: couponsAPIsConstants.API_PATH, 
  },
  actions: {
    ...CRUDModule.actions,
    getData({ commit, state }) {
      commit('SET_LOADING', true);

      commit('RESET_EDITABLE');
      console.debug(state.tableFilters);
      if(state.tableFilters) {

          if (state.tableFilters['excel'] == 1) {
            DownloadService.ddd(`${state.apiPath}`,state.tableFilters).then(()=>
              commit('SET_LOADING', false)
          );
          return;
        }
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${state.apiPath}`, { params: state.tableFilters })
          .then((res) => {
            commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);
            commit('SET_DATA_TABLE', { DataTable: res.data.result[state.dataName]?.data });
            commit('SET_RESPONSE_FILTERS', res.data.result[state.dataName]?.filters); 
            commit('SET_LOADING', false);
            resolve(res);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    }
  }
};

export default couponsModule;
