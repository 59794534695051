const path = 'DashboardModule';
const apiPath = 'admin-panal/dashboard';

const DashboardModelActions = {
    //action type
    GET_DATA : `${path}/getStatisticData`,
};

const DashboardModelAPIsConstants = {
    API_PATH : `${apiPath}`
}

export {DashboardModelActions, DashboardModelAPIsConstants};
