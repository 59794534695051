const path = 'UsersReportModule';
const apiPath = '/admin-panal/users/report';

const usersActions = {
  // action types
  GET_DATA : `${path}/getData`,
  SET_DATA_NAME : `${path}/setDataName`,
  SET_EDITABLE_ID : `${path}/setEditableId`,
  ADD_ITEM : `${path}/addItem`,
  UPDATE_ITEM : `${path}/updateItem`,
  CREATE_PAYMENT : `${path}/createPayment`,
  DELETE_ITEM : `${path}/deleteItem`,
  ACTIVE_TOGGLE : `${path}/activeToggle`,
  FIND_ITEM_BY_ID : `${path}/findItemById`,
  RESET_EDITABLE : `${path}/resetEditable`,
  RESET : `${path}/reset`,
  SET_ROWS_PER_PAGE : `${path}/setRowsPerPage`,
  SET_TOTAL_NUM : `${path}/setTotalNum`,
  SET_CURRENT_PAGE : `${path}/setCurrentPage`,
  SET_IS_CLOSE_MODAL : `${path}/setIsCloseModal`,
  SET_API_PATH : `${path}/setApiPath`,
  SET_FILTERS : `${path}/setFilters`,
  SET_SORT : `${path}/setSort`,
}

const usersAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { usersAPIsConstants, usersActions , path as ModuleName };


