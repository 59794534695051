const path = 'AdminModulesModule';
const apiPath = '/admin-panal/setting';

const AdminProfileActions = {
  // action types
  GET_DATA : `${path}/getBank`,
  RESET : `${path}/reset`,
  SUBSCRIBE : `${path}/subscribe`,
  GET_LIST_DATA : `${path}/getListData`,
  GET_PROFILE : `${path}/getProfile`,
  UPDATE_PROFILE : `${path}/updateProfile`,
  CHANGE_PASSWORD : `${path}/changePassword`,
  SETTINGS_LIST : `${path}/settingsList`,
  UPDATE_SETTINGS : `${path}/updateSettings`,
}


const AdminProfileAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { AdminProfileActions,AdminProfileAPIsConstants , path as ModuleName };
