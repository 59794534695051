const path = 'TermsConditionsModule';
const apiPath = '/admin-panal/terms-conditions';

const TermsAndConditionsActions = {
  // action types
  GET_TERMS: `${path}/getTerms`,
  UPDATE_TERMS : `${path}/updateTerms`,
}


const TermsAndConditionsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { TermsAndConditionsActions,TermsAndConditionsAPIsConstants , path as ModuleName };
