  const styles = Array.from(document.querySelectorAll('head link[rel="stylesheet"], head style'))
    .map(style => style.outerHTML)
    .join(' ');
  function addStyles(win, styles) {
    styles.forEach((style) => {
      let link = win.document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('href', style);
      win.document.head.appendChild(link);
    });
  }

function getPageHeight(printOptions) {
   return printOptions.landscape ? 780 : 1108;
}

const VueHtmlToPaper = {
   install(app, options = {}) {
      app.config.globalProperties.$htmlToPaper = (el, printOptions = {}) => {
         let defaultName = '_blank',
            defaultSpecs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
            defaultReplace = true;
         let {
            name = defaultName,
            specs = defaultSpecs,
            replace = defaultReplace,
         } = options;
         const { landscape, disablePageNumber, autoSize } = printOptions;

         const element = window.document.getElementById(el);

         if (!element) {
            alert(`Element to print #${el} not found!`);
            return;
         }

         const url = '';
         const win = window.open(url, name, specs, replace);
         const lang = document.documentElement.getAttribute('lang');
         const bodyClasses = document.body.classList.value;
         const pageLayoutClasses = document.getElementById('pageLayout').classList.value;
         const pageHeight = getPageHeight(printOptions);

         win.document.write(`
          <html lang="${lang}">
            <head>
              <title>${window.document.title}</title>
             <link rel="stylesheet" type="text/css" href="/assets/css/app.css">
              <link rel="stylesheet" type="text/css" href="/assets/css/chunk-vendors.css">
              <link rel="stylesheet" type="text/css" href="/assets/css/reports.css">
            </head>
            <body id="kt_body" class="${bodyClasses} status_printing overlay">
                <div id="app" class="d-flex flex-column flex-root">
                    <div id="pageLayout" class="${pageLayoutClasses}">
                        <div style="width: 99% !important;">
                           <table style="width: 100%">
                              <tbody><tr><td><div>${element.innerHTML}</div></td></tr></tbody>
                              <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                           </table>
                        </div>
                    </div>
                </div>
                <div id="overlayLayer" class="overlay-layer">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </body>
            <script>                
                document.addEventListener("DOMContentLoaded", function(){
                     if (${disablePageNumber ? 'true' : 'false'}) return;
                     setTimeout(() => {
                        var totalPages = Math.ceil(document.getElementById('app').scrollHeight / ${pageHeight});
   
                        for (var i = 1; i <= totalPages; i++) {
                           var pageNumberDiv = document.createElement("div");
                           var pageNumber = document.createTextNode(i + "/" + totalPages);
                           pageNumberDiv.style.position = "absolute";
                           pageNumberDiv.style.top = "calc((" + i + " * ${pageHeight}px) - 18px)";
                           pageNumberDiv.style.height = "16px";
                           pageNumberDiv.style.zIndex = 1;
                           pageNumberDiv.appendChild(pageNumber);
                           document.documentElement.append(pageNumberDiv);
                           pageNumberDiv.style.left = "50%";
                        }
                     }, 1000);
                });
                window.addEventListener("load", function() {
                    var element = document.getElementById("overlayLayer");
                    element.classList.remove("overlay-layer");
                    setTimeout(() => {
                     window.print();
                     ${process.env.VUE_APP_REPORTS_DEBUG ? '' : ''}
                    }, 2000);
                });
            </script>
          </html>
        `);

         addStyles(win, [
            '/css/print.css',
            autoSize ? '/css/auto.css' : landscape ? '/css/landscape.css' : '/css/A4.css',
         ]);

         setTimeout(() => {
            win.document.close();
            win.focus();
         }, 500);

         return true;
      };
   },
};

export default VueHtmlToPaper;
