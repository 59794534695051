import CRUDModule from './CRUDModule';
import { salesAPIsConstants, salesActions } from '../enums/SalesReportEnums';
import axios from 'axios';
import DownloadService from '@/core/services/DownloadService';

const SalesReportModule = {
  ...CRUDModule,  // Inherit all functionality from CRUDModule
  namespaced: true,
  state: {
    ...CRUDModule.state,
    sum: null,  // Ensure the sum state is independent for sales
    apiPath: salesAPIsConstants.API_PATH,  // Default to the sales API path
  },
  actions: {
    ...CRUDModule.actions,
    getData({ commit, state }) {
      commit('SET_LOADING', true);
      commit('RESET_EDITABLE');
      if(state.tableFilters) {

          if (state.tableFilters['excel'] == 1) {
            DownloadService.ddd(`${state.apiPath}`,state.tableFilters).then(()=>
              commit('SET_LOADING', false)
          );
          return;
        }
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.apiPath}`, { params: state.tableFilters })
          .then((res) => {
            commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);
            commit('SET_DATA_TABLE', { DataTable: res.data.result[state.dataName]?.data });
            commit('SET_SUM', res.data.result[state.dataName]?.sum); 
            commit('SET_RESPONSE_FILTERS', res.data.result[state.dataName]?.filters); 
            commit('SET_LOADING', false);
            resolve(res);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    }
  }
};

export default SalesReportModule;
