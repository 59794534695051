
import AuthAdminModule from './modules/AuthAdminModule';
import CRUDModule from './modules/CRUDModule';
import AdminModulesModule from './modules/AdminModulesModule';
import PartnerModule from './modules/PartnerModule';
import SalesReportModule from './modules/SalesReportModule';
import UsersReportModule from './modules/UsersReportModule';
import CouponsReportModule from './modules/CouponsReportModule';
import VenderModule from './modules/VenderModule';
import ModalModule from './modules/ModalModule';
import DashboardModule from './modules/DashboardModule';
import TermsConditionsModule from './modules/TermsConditionsModule';

const adminStore = {
    AuthAdminModule,
    AdminModulesModule,
    ModalModule,
    UsersReportModule,
    SalesReportModule,
    CouponsReportModule,
    PartnerModule,
    VenderModule,
    DashboardModule,
    CRUDModule,
    TermsConditionsModule
};

export default adminStore;
