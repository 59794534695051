import axios from 'axios';

const ListModule = {
  namespaced: true,
  state: {
    list: {},
    cacheLists: {},
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list[payload.listName] = payload.list;
      state.cacheLists[payload.listName + JSON.stringify(payload.filters)] =
        payload.list;
    },
  },
  actions: {
    getListByName({ commit, state }, options) {
      let listName = typeof options === 'object' ? options.name : options;

      let path = 'core/list/';
      if (listName === 'getConstList') path = 'core/common/';

      if (
        listName + JSON.stringify(options.filters ?? {}) in
        state.cacheLists
      )
        return;

      return new Promise((resolve, reject) => {
        axios
          .get(path + listName, {
            params: {
              filters: options.filters ?? {},
            },
          })
          .then((res) => {
            commit('SET_LIST', {
              listName,
              list:
                listName === 'get-const-list'
                  ? res.data.result.lists
                  : res.data.result.list,
              filters: options.filters ?? {},
            });
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    getListByName: (state) => (name) => {
      return state.list[name];
    },
  },
};
export default ListModule;
