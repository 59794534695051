import axios from 'axios';
import {TermsAndConditionsAPIsConstants} from "../enums/TermsAndConditions";

const TermsConditionsModule = {
  namespaced: true,
  state: {
    data: [],
    loadingItem: false,
    itemEditable: {},
  },
  mutations: {
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    SET_ITEM_EDITABLE(state, payload) {
      state.itemEditable = payload.itemEditable;
    }
  },
  actions: {
    updateTerms({ commit, state }, { credentials }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${TermsAndConditionsAPIsConstants.API_PATH}`, credentials)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTerms({ commit, state }) {
      commit('SET_LOADING_ITEM', true);
      return new Promise((resolve, reject) => {
      axios
        .get(`${TermsAndConditionsAPIsConstants.API_PATH}`)
        .then((res) => {
          commit('SET_LOADING_ITEM', false);
          commit('SET_ITEM_EDITABLE', {
            itemEditable: res.data.result?.terms_condition,
          });
          resolve(res);
        })
        .catch((error) => {
          commit('SET_LOADING_ITEM', false);
          reject(error);
        });
      });
    },
  }
};
export default TermsConditionsModule;
